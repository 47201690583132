import React from "react";

import "./style.scss";

const HeaderInsegne = ({title, text, logo, link, linkText, headerImg}) => (
    <section className="header-insegne">
        <div className="info">
            <img className="logo" src={logo} data-aos="fade-right" data-aos-offset="0" alt={logo} />
            <h3 data-aos="fade-right" data-aos-offset="150">{title}</h3>
            {text &&
                <p 
                    data-aos="fade-right" 
                    data-aos-offset="200"
                    dangerouslySetInnerHTML={{__html: text}}
                ></p>
            }
            {(link && linkText) &&
                <a className="btn green" href={link} aria-label={`${linkText}`}>{linkText}</a>
            }
        </div>
        <img className="header rounded" src={headerImg} />
    </section>
)

export default HeaderInsegne
