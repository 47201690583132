import React from "react";

import "./style.scss";

const InsegnaNumber = ({image, number, text}) => (
    <div className="single-number">
        <img src={image} data-aos="fade-up" alt="" />
        <span>
            <h4 data-aos="fade-right" data-aos-offset="150">{number}</h4>
            <h5 data-aos="fade-left" data-aos-offset="150">{text}</h5>
        </span>
    </div>

)

export default InsegnaNumber
