import React, { useState, useEffect } from "react";
import { Link } from "gatsby";
import language_config from "../../../../language-config";

import "../style.scss";
import "./style.scss";

const CardCareer = ({to, logoInsegna, date, position, onClick, href}) => {
    let [path, setPath] = useState('/');

    useEffect(() => {
        let newPath = ''
        if (typeof window !== 'undefined') {
            newPath = ((language_config[window.localStorage.language].is_default ? '' : ('/' + window.localStorage.language)) + '/')
        } else {
            newPath = '/'
        }
        setPath(newPath)
    }, []);

    const Wrapper = ({ children }) => {
        if (href) {
            return (<a className="card card-career rounded" onClick={onClick} href={href} aria-label={`${position}`}>{children}</a>)
        } else {
            return (<Link className="card card-career rounded" to={`${path}${to}`} onClick={onClick} aria-label={`${position}`}>{children}</Link>)
        }
    }
    return (
        <Wrapper>
            <span className="logo-career ie">
                <img alt="logo" src={logoInsegna} />
            </span>
            <span>
                <span className="subtitle">{date}</span>
                <h5>{position}</h5> 
            </span>
        </Wrapper>
    )
}

export default CardCareer
